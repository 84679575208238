import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from './Layout/navbar';
import { useEffect } from 'react';
// import Home from './Component/Pages/home';
import Footer from './Layout/footer';
import MainRoutes from './Routes';
import Products from './Pages/products';
import { useLocation } from 'react-router-dom';


function App() {
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(()=>{
    document.title = "Garuda";
  },[])
console.log("pathname",location.pathname);
  return (
    <>
      <Navbar/>
      <MainRoutes/>
      
      {/* { (location.pathname === "/" && <Products />)}</Home>} */}
      {pathname === "/" ||pathname === "/home" ? <Products/> : ""}
      {/* <Products/> */}
      <Footer/>
    </>
  );
}

export default App;
