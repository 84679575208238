import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import { FaWhatsapp } from "react-icons/fa";
import './footer.css';

const Footer = () => {
    return (
        <footer className='footer pt-5 text-light text-center'>
            <div className="container">
                <div className='row text-center text-md-left'>
                    <div className='col-xl-4 col-lg-4 col-md-6 mb-4 mb-md-0'>
                        <h5 className='mb-3'>Locate Us</h5>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3705.239241618742!2d72.12968067527733!3d21.770999880070583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDQ2JzE1LjYiTiA3MsKwMDcnNTYuMSJF!5e0!3m2!1sen!2sin!4v1717586033988!5m2!1sen!2sin"
                            style={{ width: '100%', height: 200, border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-6 mb-4 mb-md-0'>
                        <h5 className='mb-3'>Connect</h5>
                        <div>
                            <a href="mailto:info@testmail.com" className="d-block mb-2 text-light">
                                <Icon.EnvelopeFill className="me-2 footer-icon" /> info@gmail.com
                            </a>
                            <a href="tel:+918000844341" className="d-block mb-2 text-light">
                                <Icon.Telephone className="me-2 footer-icon" /> +91 8000844341 (IN)
                            </a>
                        </div>
                        <div className="justify-content-center justify-content-md-start mt-3">
                            <NavLink to="https://www.instagram.com/garuda_trades_/" target="_blank" className="mx-2 text-light" title="Instagram">
                                <Icon.Instagram className='footer-icon' size={24} />
                            </NavLink>
                            <NavLink to="" target="_blank" className="mx-2 text-light footer-icon" title="Facebook">
                                <Icon.Facebook className='footer-icon' size={24} />
                            </NavLink>
                            <a
                      href="https://wa.me/+918000844341"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-2"
                    >
                      <FaWhatsapp className='footer-icon' size={26} />
                    </a>
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-12 text-center text-md-left'>
                        <h5 className='mb-3'>Contact Us</h5>
                        <address className="mb-2">
                           Office-9, Ambika House, Nirmal Nagar, Bhavnagar, PIN-364002
                        </address>
                        <div>
                            Mobile No: <a href="tel:+91 8000844341" className="text-light">+91 8000844341 (IN)</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;















// import React from 'react'
// import { NavLink } from 'react-router-dom';
// import * as Icon from "react-bootstrap-icons";
// import whatsapp from '../Image/whatsapp.png';


// const Footer = () => {
//     return (
//         <>
//             <div className='p-2 mt-5 footer'>
//                 <div className="container footer_content">
//                     <div className='row'>
//                         <div className='col-xl-3 col-lg-4 col-md-12 col-12 text-center'>
//                             <iframe title='Locate Us' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d620.5549148391373!2d72.8110821377772!3d21.226802781296634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f224ffe3d5d%3A0x9f1d02000cd337d1!2sShree%20Hari%20Filter%20House!5e0!3m2!1sen!2sin!4v1703569448688!5m2!1sen!2sin" style={{ width: 300, height: 200 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
//                         </div>

//                         <div className='col-xl-3 col-lg-4 col-md-12 col-12 text-center'>
//                             <div>
//                                 <a href="mailto:info@greenweblab.com" ><Icon.EnvelopeFill /><span className="d-none mx-2 d-md-inline-block">info@gmail.com</span></a>
//                                 {/* <span className="mx-md-2 d-inline-block"></span> */}
//                                 <a href="tel:+918799160191"><Icon.Telephone /> <span className="d-none d-md-inline-block">+91 9601065391 (IN)</span></a>
//                             </div>
//                             <div className="icons d-flex">
//                                 <NavLink to="" target="_blank" className="mx-2" title="Instagram"><Icon.Instagram /></NavLink>
//                                 <NavLink to="" target="_blank" className="mx-2" title="Facebook"><Icon.Facebook /></NavLink>
//                                 <a href="https://wa.me/919601065391" target="_blank" rel="noopener noreferrer">
//                                     <img src={whatsapp} style={{ width: 30 }} alt="" className="service_icon"/>
//                                 </a>
//                             </div>
//                         </div>

//                         <div className='col-xl-3 col-lg-4 col-md-12 col-12 text-center'>
//                             <h3 className='fw-bold'>Contact Us</h3>
//                             <div className="address">
//                                 Shop No.11, Santvan Residency, Navo Mahollo, Singanpore, Causeway Road-Surat, Gujarat 395004
//                             </div>
//                             <div className="mobile">
//                                 Mobile No: +919601065391
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Footer;







{/* <div className='col-xl-3 col-lg-4 col-md-12 col-12 text-center'>
                            <h3 className='fw-bold'>Policies</h3>
                            <div>
                                <ul className='ps-0'>
                                    <li>
                                        <NavLink to="/privacy" className='text-decoration-none text-black' target="_blank">Privacy Policy</NavLink>
                                    </li>
                                    <li className='mt-2'>
                                        <NavLink to="/cookie" className="text-decoration-none text-black" target="_blank">Cookie Policy</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div> */}