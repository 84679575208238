import React from 'react';
import '../Pages/products.css';

const ProductCard = ({ product }) => {
  const { image, name, keyBenefits } = product;

  return (
    <div className="product-card">
      <img src={image} alt={name} className="product-image" />
      <div className="product-details">
        <h3 className="product-name text-center mb-2">{name}</h3>
        <ul className="key-benefits">
          {keyBenefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      
      </div>
    </div>
  );
};

export default ProductCard;
