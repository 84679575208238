import bold from '../assets/Image/peanut.webp';
import java from '../assets/Image/java.webp';
import blacksesame from '../assets/Image/blacksesame.webp';
import whitesesame from '../assets/Image/whitesesame.webp';
import cowdung from '../assets/Image/cowdung.webp';
import ProductCard from '../Component/productcard';

const productData = [
    {
        image: bold,
        name: 'BOLD Peanut',
        keyBenefits: ['More protein than meat', '2 times more protein than eggs', 'contains 40% to 53% oil', "source of calcium, phosphorus, iron", 'source of zinc and boron', 'conatain vitamin E & vitamin B complex'],
    },
    {
        image: java,
        name: 'JAVA Peanut',
        keyBenefits: ['More protein than meat', '2 times more protein than eggs', 'contains 40% to 53% oil', "source of calcium, phosphorus, iron", 'source of zinc and boron', 'conatain vitamin E & vitamin B complex'],
    },
    {
        image: blacksesame,
        name: 'Black Sesame',
        keyBenefits: ['source of protein, healthy fats, fiber.', 'source of calcium and magnesium,', 'low in carbs but high in protein and fiber', "contain antioxidants like sesamol and sesamin", 'contain zinc, selenium, copper, and iron','May aid digestion'],
    },
    {
        image: whitesesame,
        name: 'White Sesame',
        keyBenefits: ['source of protein, healthy fats, fiber.', 'source of calcium and magnesium,', 'low in carbs but high in protein and fiber', "contain antioxidants like sesamol and sesamin", 'contain zinc, selenium, copper, and iron','May aid digestion'],
    },
    {
        image: cowdung,
        name: 'Cow Dung related Products',
        keyBenefits: ['rich in nitrogen, phosphorus, and potassium', 'use in produce biogas. ', 'fuel source for cooking and heating', "can be use in Seedling Pots", 'Promotes Beneficial Microorganisms', 'improve soils physical structure'],
    },

];



const Products = () => {
    return (
      <div className='products container'>
        <h1 className='text-center mb-5 products-heading'>Agricultural Products</h1>
        <div className='row'>
          {productData.map((item) => (
            <div className='col-12 col-md-6 col-xl-4' key={item.name}>  {/* Added key prop */}
              <ProductCard product={item} />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Products;
