import React from "react";
import garuda from '../../src/assets/Image/logo.svg';
import './navbar.css';
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand logo" href="#">
            <img className="logo-image" style={{ width: "75px", height: "75px" }} src={garuda} alt="Garuda Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact = "true" to='/home'  aria-current="page">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact = "true" to="/team">Team</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact = "true" to ="/aboutus" >About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link"  exact = "true" to ="/blog">Impex Edge</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact = "true" to ="/companies">Companies</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact = "true" to ="/connect">Connect</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;









