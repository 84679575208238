import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';


const Home = React.lazy(() => import('../Pages/home'));
const Team = React.lazy(() => import('../Pages/team'));
const Companies = React.lazy(() => import('../Pages/companies'));
const Blog = React.lazy(() => import('../Pages/blog'));
const PageNotFound = React.lazy(() => import('../Pages/pageNotFound'));
const Connect = React.lazy(() => import('../Pages/connect'));
const AboutUs = React.lazy(()=>import('../Pages/aboutus'));
const Products =React.lazy(()=>import('../Pages/products'));


const MainRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/team" element={<Team />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/connect" element={<Connect />} />
                <Route path="/aboutus" element={<AboutUs/>}/>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
}

export default MainRoutes;